/* SidePanel.css */
.side-panel {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    background: #1e1e1e;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
    z-index: 1000;
    transform: translateX(100%);
    width: 400px;
    color: #e0e0e0;
    font-family: 'Arial', sans-serif;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.side-panel.open {
    transform: translateX(0);
}

.side-panel-content {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-height: min-content;
}

.side-panel-content > * {
    flex-shrink: 0;
}

.close-btn {
    position: sticky;
    top: 10px;
    right: 10px;
    z-index: 1;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #e0e0e0;
    align-self: flex-end;
}

.function-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.function-name-input {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #555;
    border-radius: 4px;
    background-color: #2a2a2a;
    color: #e0e0e0;
    font-size: 16px;
}

.function-actions {
    display: flex;
    gap: 10px;
}

.action-btn {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    transition: background-color 0.3s;
}

.action-btn:hover {
    opacity: 0.8;
}

.action-btn.delete {
    background-color: #d32f2f;
    color: white;
}

.function-parameters {
    margin-bottom: 15px;
    font-size: 14px;
    color: #bdbdbd;
}

.editor-container {
    height: 300px;
    min-height: 300px;
    border: 1px solid #555;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 20px;
}

.button-group {
    margin-bottom: 20px;
}

.primary-btn, .secondary-btn {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: background-color 0.3s;
}

.primary-btn {
    background-color: #2196f3;
    color: white;
}

.secondary-btn {
    background-color: #4caf50;
    color: white;
    margin-top: 10px;
}

.primary-btn:hover, .secondary-btn:hover {
    opacity: 0.9;
}

.section-title {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 18px;
    color: #e0e0e0;
}

.param-input {
    margin-bottom: 15px;
}

.param-input label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #bdbdbd;
}

.param-input input {
    width: 100%;
    padding: 8px;
    border: 1px solid #555;
    border-radius: 4px;
    background-color: #2a2a2a;
    color: #e0e0e0;
}

.execution-result {
    margin-top: 20px;
    background-color: #2a2a2a;
    border: 1px solid #555;
    border-radius: 4px;
    padding: 15px;
    overflow-x: auto;
}

.execution-result h3 {
    margin-top: 0;
    font-size: 16px;
    color: #e0e0e0;
}

.execution-result pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    color: #4caf50;
    font-size: 14px;
    margin: 0;
    padding: 10px 0;
}

.draggable-handle {
    width: 20px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: ew-resize;
    background-color: transparent;
}

.draggable-icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 20px;
    background-color: #e0e0e0;
    cursor: ew-resize;
}

.monaco-editor {
    height: 100%;
}

/* Scrollbar styles */
.side-panel::-webkit-scrollbar {
    width: 8px;
}

.side-panel::-webkit-scrollbar-track {
    background: #1e1e1e;
}

.side-panel::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    border: 2px solid #1e1e1e;
}

.side-panel::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}