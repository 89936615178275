.react-flow__node-turbo {
  border-radius: 10px;
  display: flex;
  height: 70px;
  min-width: 150px;
  font-family: 'Fira Mono', Monospace;
  font-weight: 500;
  letter-spacing: -0.2px;
  box-shadow: 10px 0 15px rgba(42, 138, 246, 0.3),
    -10px 0 15px rgba(233, 42, 103, 0.3);
}

.turbo-node {
  position: relative;
}

.react-flow__node-turbo .wrapper {
  overflow: hidden;
  display: flex;
  padding: 2px;
  position: relative;
  border-radius: 10px;
  flex-grow: 1;
}

.gradient:before {
  content: '';
  position: absolute;
  padding-bottom: calc(100% * 1.41421356237);
  width: calc(100% * 1.41421356237);
  background: conic-gradient(
    from -160deg at 50% 50%,
    #e92a67 0deg,
    #a853ba 120deg,
    #2a8af6 240deg,
    #e92a67 360deg
  );
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
}

.react-flow__node-turbo.selected .wrapper.gradient:before {
  content: '';
  background: conic-gradient(
    from -160deg at 50% 50%,
    #e92a67 0deg,
    #a853ba 120deg,
    #2a8af6 240deg,
    rgba(42, 138, 246, 0) 360deg
  );
  animation: spinner 4s linear infinite;
  transform: translate(-50%, -50%) rotate(0deg);
  z-index: -1;
}

@keyframes spinner {
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

.react-flow__node-turbo .inner {
  background: rgb(17, 17, 17);
  padding: 16px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  color: rgb(243, 244, 246);
}

.react-flow__node-turbo .icon {
  margin-right: 8px;
}

.react-flow__node-turbo .body {
  display: flex;
}

.react-flow__node-turbo .title {
  font-size: 16px;
  line-height: 1.2;
}

.react-flow__node-turbo .subline {
  font-size: 12px;
  color: #777;
}

.turbo-node .delete-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background: white;
  border: 1px solid black;
  color: black;
  cursor: pointer;
  font-size: 12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 10;
}

.turbo-node:hover .delete-button {
  opacity: 1;
}

.turbo-node .delete-button:hover {
  background: #f0f0f0;
}