.functions-library {
  width: 250px;
  background-color: #1e1e1e;
  padding: 20px;
  overflow-y: auto;
  color: #e0e0e0;
}

.functions-library h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 24px;
}

.function-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.function-item-wrapper {
  position: relative;
}

.function-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2a2a2a;
  border: 1px solid #3a3a3a;
  border-radius: 6px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  padding: 12px;
  position: relative; /* Add this line */
}

.function-item:hover {
  background-color: #333333;
  border-color: #4a4a4a;
}

.draggable-node {
  padding: 10px;
  border: 1px solid #3a3a3a;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #2a2a2a;
  cursor: move;
  display: flex;
  align-items: center;
  color: #e0e0e0;
}

.function-drag-area {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.function-drag-area svg {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  fill: #e0e0e0;
}

.create-function {
  cursor: pointer;
  background-color: #2c3e50;
  color: #ecf0f1;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 12px;
  border-radius: 6px;
}

.create-function:hover {
  background-color: #34495e;
}

.create-function .function-icon {
  margin-right: 10px;
}

.delete-function-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #e74c3c;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: opacity 0.3s ease, color 0.3s ease;
  position: absolute; /* Change to absolute positioning */
  right: 12px; /* Position from the right edge */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for exact centering */
}

.delete-function-btn:hover {
  opacity: 1;
  color: #c0392b;
}

.delete-function-btn svg {
  width: 16px;
  height: 16px;
}

.loading {
  text-align: center;
  color: #888;
  font-style: italic;
  margin-top: 20px;
}

.delete-btn {
  background: none;
  border: none;
  color: #ff4d4d;
  cursor: pointer;
  margin-left: 10px;
}

.delete-btn:hover {
  color: #ff0000;
}